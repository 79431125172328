import React, {useState} from 'react';
import {BrowserRouter, Link, Route, Routes, useParams} from "react-router-dom";

import Sidebar from "./components/Sidebar";
import AuthInitializer from './components/AuthInitializer';
import LoginScreen from "./components/LoginScreen";

import {AuthContext, AuthInfo, AuthStatus} from "./utils/Auth"
import Pages, {Page} from "./utils/Pages";

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import ToastContainer from './components/ToastContainer';
import { ToastContext, ToastInfo } from './utils/ToastContext';

function App(): JSX.Element {
    const authId = window.localStorage.getItem("auth-id") || undefined;
    const status = authId ? AuthStatus.PENDING : AuthStatus.NOT_AUTHENTICATED;
    const [authInfo, setAuthInfo] = useState<AuthInfo>({ status, authId });

    const [toasts, setToasts] = useState<ToastInfo[]>([]);

    const updateAuthInfo = (info: AuthInfo) => {
        if (info.authId) window.localStorage.setItem("auth-id", info.authId);
        else window.localStorage.removeItem("auth-id");

        setAuthInfo(info);
    }

    const addToast = (title: string, message: JSX.Element | string) => {
        const toast: ToastInfo = {
            title,
            message,
            timestamp: Date.now(),
            visible: true,
        }

        setToasts([...toasts, toast]);
    }

    const removeToast = (toast: ToastInfo) => {
        toast.visible = false;
        setToasts([...toasts]);
        /*
        const toastsCopy = [...toasts];
        toastsCopy.splice(toasts.indexOf(toast), 1);
        setToasts(toastsCopy);
        */
    }

    return (
        <ToastContext.Provider value={{toasts, addToast, removeToast}} >
            <AuthContext.Provider value={{info: authInfo, updateInfo: updateAuthInfo}}>
                {
                    authInfo.status === AuthStatus.PENDING ? <AuthInitializer /> :
                    authInfo.status === AuthStatus.NOT_AUTHENTICATED ? <LoginScreen /> :
                    <Content />
                }
            </AuthContext.Provider>
        </ToastContext.Provider>
    );
}

function Content(): JSX.Element {
    return (
        <BrowserRouter>
            <ToastContainer position={"position-fixed bottom-0 start-0"} showDeleted={false} className="p-3" />
            <div className="px-4 p-3 m-4 mb-0" style={{borderRadius: "20px", display: "inline-block", width: "max-content"}}>
                <img className="me-3" alt="logo" src="https://feargames.it/static/images/logo-lq.png" style={{height: "45px", float: "left"}} />
                <p className="h2" style={{float: "left"}}>Amministrazione FearGames</p>
            </div>

            <div className="m-3">
                <div className="d-sm-flex m-0 p-0">
                    <div id="sidebar-container" className="m-2 shadow">
                        <Sidebar />
                    </div>

                    <div className="flex-fill p-2">
                        <div className="shadow mb-4 ps-4 mt-0" style={{backgroundColor: "white", borderRadius: "20px"}}>
                            <Routes>
                                { Pages.map(page => <Route path={page.path} element={<Breadcrumbs page={page} />} />) }
                            </Routes>
                        </div>

                        <div className="shadow p-3" style={{backgroundColor: "white", borderRadius: "20px"}}>
                            <Routes>
                                { Pages.map(page => <Route path={page.path} element={<page.component />} />) }
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pe-4">
                <p style={{textAlign: "center", userSelect: "none"}}>© 2015 - {new Date().getFullYear()} Copyright: FearGames Network</p>
            </div>
        </BrowserRouter>
    );
}

function Breadcrumbs(props: {page: Page}): JSX.Element {
    const params = useParams();
    const crumbs: JSX.Element[] = [];
    let currentPage: Page | undefined = props.page;
    while (currentPage) {
        let path = currentPage.path;
        for (const param in params) {
            path = path.replaceAll(`:${param}`, params[param]!);
        }
        crumbs.unshift(<Breadcrumb.Item className="my-3"><Link to={path}>{currentPage.title}</Link></Breadcrumb.Item>);
        currentPage = currentPage.topPage;
    }
    return <Breadcrumb>{crumbs}</Breadcrumb>;
}

export default App;
