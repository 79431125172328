import {PermissionLevel} from "../utils/Auth";

import Home from "../pages/Home";
import Chatlog from "../pages/Chatlog";
import CondivisioneRete from "../pages/CondivisioneRete";
import StaffStats from "../pages/StaffStats";
import ModAmmesse from "../pages/ModAmmesse";
import ModificaModAmmesse from "../pages/ModificaModAmmesse";
import RecuperoAccount from "../pages/RecuperoAccount";
import BloccoUUID from "../pages/BloccoUUID";
import CambioAccount from "../pages/CambioAccount";
import ModificaCondivisioneRete from "../pages/ModificaCondivisioneRete";
import UltimeNotizie from "../pages/Profilo";
import AnticheatsLogs from "../pages/AnticheatsLogs";
import TeamSpeakController from "../pages/TeamSpeakController";
import SpeedBuilders from "../pages/SpeedBuilders";

const pages: Page[] = [
  Home,
  Chatlog,
  AnticheatsLogs,
  CondivisioneRete,
  ModificaCondivisioneRete,
  StaffStats,
  ModAmmesse,
  ModificaModAmmesse,
  RecuperoAccount,
  CambioAccount,
  BloccoUUID,
  SpeedBuilders,
  UltimeNotizie,
  TeamSpeakController,
];

export interface Page {
  title: string;
  description?: string;
  path: string;
  icon: string;
  topPage?: Page;
  showInSidebar: boolean;
  showInHome: boolean;
  level: PermissionLevel;
  component: () => JSX.Element;
}

export default pages;
