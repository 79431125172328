export function formatTimestamp(timestamp: string) {
  const regex = /([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2}).+/gm;
  return timestamp.replace(regex, "$3/$2/$1 $4:$5:$6");
}

export function parsePossibleInt(value: string | number | undefined, defaultValue = 0) {
  if (typeof value === "string") return parseInt(value);
  return value || defaultValue;
}

export function secondsToDays(seconds: number): string {
  //seconds = Math.abs(seconds);

  const days = Math.floor(seconds / (3600*24));
  const hours = Math.floor(seconds % (3600*24) / 3600);
  const minutes = Math.floor(seconds % 3600 / 60);
  const sekonds = Math.floor(seconds % 60);

  return `${days}d ${hours}h ${minutes}m ${sekonds}s`;
}