import {FormEvent, useContext, useState} from "react";

import {PermissionLevel} from "../utils/Auth";
import {Page} from "../utils/Pages";
import useAPI, {filterSuccess} from "../utils/API";
import { ToastContext } from "../utils/ToastContext";
import { Alert, Tab, Tabs } from "react-bootstrap";
import PaginatedTable from "../components/PaginatedTable";

interface PlayerTimeEntry {
  player: string;
  buildrawname: string;
  time: string;
}

function Render(): JSX.Element {
  const fetchApi = useAPI();

  const toastContext = useContext(ToastContext);

  const [playerName, setPlayerName] = useState("");
  const [buildRawName, setBuildRawName] = useState("");
  const [time, setTime] = useState(0.0);
  const [rowsPerPage, setRowsPerPage] = useState<number | undefined>(30);

  const [timesData, setTimesData] = useState<PlayerTimeEntry[] | undefined>(undefined);
  const [pending, setPending] = useState(false);

  const [tabActiveKey, setTabActiveKey] = useState<string | undefined>(undefined);

  const fetchPlayerTimes = (event: FormEvent) => {
    event.preventDefault();

    setPending(true);

    fetchApi("/v1/speedbuilders?uuid=" + playerName, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(filterSuccess)
    .then(response => response.json())
    .then(data => setTimesData(data))
    .finally(() => setPending(false));
  }

  const addOrUpdatePlayerTime = (event: FormEvent) => {
    event.preventDefault();

    setPending(true);

    const body = {
      uuid: playerName,
      buildrawname: buildRawName,
      time,
    };

    fetchApi("/v1/speedbuilders", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    .then(filterSuccess)
    .then(response => response.json())
    .then(() => {
      toastContext.addToast("SpeedBuilders", "Aggiunto / Aggiornato tempo");
    })
    .catch(() => {
      toastContext.addToast("SpeedBuilders", "Errore!");
    })
    .finally(() => setPending(false));
  }

  const deletePlayerTime = (event: FormEvent) => {
    event.preventDefault();

    const body = {
      uuid: playerName,
      buildrawname: buildRawName,
    };

    fetchApi("/v1/speedbuilders", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    .then(filterSuccess)
    .then(response => response.json())
    .then(() => {
      toastContext.addToast("SpeedBuilders", "Eliminato tempo");
    })
    .catch(() => {
      toastContext.addToast("SpeedBuilders", "Errore!");
    })
    .finally(() => setPending(false));
  }

  return (
    <div>
      <Tabs defaultActiveKey={"playerTimes"} activeKey={tabActiveKey} onSelect={() => setTabActiveKey(undefined)}>
        <Tab eventKey={"playerTimes"} title="Tempi Player" className="p-3 border rounded-bottom-4">
          <Alert variant="warning">Lascia i campi vuoti per vedere tutti i dati.</Alert>

          <form onSubmit={fetchPlayerTimes} className="mb-3">
            <div className="input-group my-2">
              <span className="input-group-text">Nome giocatore</span>
              <input type="text" name="playerName" className="form-control" value={playerName} onChange={e => setPlayerName(e.target.value)} />
            </div>

            <div className="input-group my-2">
              <span className="input-group-text">Nome build (raw)</span>
              <input type="text" name="playerName" className="form-control" value={buildRawName} onChange={e => setBuildRawName(e.target.value)} />
            </div>

            <div className="input-group my-2">
              <span className="input-group-text">Righe per pagina</span>
              <input type="number" name="rowsPerPage" className="form-control" value={rowsPerPage} onChange={e => setRowsPerPage(parseInt(e.target.value))} />
            </div>

            <button type="submit" className="btn btn-primary rounded-pill w-100" disabled={pending}>
              { pending ? <span className="spinner-border spinner-border-sm" /> : "Cerca" }
            </button>
          </form>

          {
            timesData !== undefined &&
            <PaginatedTable data={timesData.filter(entry => entry.buildrawname.includes(buildRawName)).sort((a, b) => parseFloat(a.time) - parseFloat(b.time))} perPage={rowsPerPage || 30} headers={["Player", "Build Name", "Time", "Azioni"]} rowMapper={GetRowMapper(setTabActiveKey, setPlayerName, setBuildRawName, setTime)} />
          }
        </Tab>
        <Tab eventKey={"addOrUpdatePlayerTime"} title="Aggiungi o aggiorna tempo" className="p-3 border rounded-bottom-4">
        <form onSubmit={addOrUpdatePlayerTime}>
            <div className="input-group my-2">
              <span className="input-group-text">Nome giocatore*</span>
              <input type="text" name="playerName" className="form-control" value={playerName} onChange={e => setPlayerName(e.target.value)} required />
            </div>

            <div className="input-group my-2">
              <span className="input-group-text">Nome build (raw)*</span>
              <input type="text" name="playerName" className="form-control" value={buildRawName} onChange={e => setBuildRawName(e.target.value)} required />
            </div>

            <div className="input-group my-2">
              <span className="input-group-text">Tempo (secondi)*</span>
              <input type="number" inputMode="decimal" step={0.1} name="playerName" className="form-control" value={time} onChange={e => setTime(parseFloat(e.target.value))} required />
            </div>

            <button type="submit" className="btn btn-primary rounded-pill w-100" disabled={pending}>
              { pending ? <span className="spinner-border spinner-border-sm" /> : "Aggiungi / Aggiorna" }
            </button>
          </form>
        </Tab>
        <Tab eventKey={"deletePlayerTime"} title="Elimina tempo" className="p-3 border rounded-bottom-4">
          <form onSubmit={deletePlayerTime}>
            <div className="input-group my-2">
              <span className="input-group-text">Nome giocatore*</span>
              <input type="text" name="playerName" className="form-control" value={playerName} onChange={e => setPlayerName(e.target.value)} required />
            </div>

            <div className="input-group my-2">
              <span className="input-group-text">Nome build (raw)*</span>
              <input type="text" name="playerName" className="form-control" value={buildRawName} onChange={e => setBuildRawName(e.target.value)} required />
            </div>

            <button type="submit" className="btn btn-danger rounded-pill w-100" disabled={pending}>
              { pending ? <span className="spinner-border spinner-border-sm" /> : "Elimina" }
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
}

function GetRowMapper(setTabActiveKey: (tabName: string) => void, setPlayerName: (playerName: string) => void, setBuildRawName: (buildrawname: string) => void, setTime: (time: number) => void): (entry: PlayerTimeEntry) => JSX.Element {
  return (entry: PlayerTimeEntry): JSX.Element => {
    const updateTime = (playerName: string, buildRawName: string, time: number) => {
      setTabActiveKey("addOrUpdatePlayerTime");
      setPlayerName(playerName);
      setBuildRawName(buildRawName);
      setTime(time);
    }
    
    const deleteTime = (playerName: string, buildRawName: string) => {
      setTabActiveKey("deletePlayerTime");
      setPlayerName(playerName);
      setBuildRawName(buildRawName);
    }

    return (
      <tr key={entry.player + "-" + entry.buildrawname + "-" + entry.time}>
        <td>{entry.player}</td>
        <td>{entry.buildrawname}</td>
        <td>{entry.time}</td>
        <td>
          <button className="btn btn-primary me-1" onClick={() => updateTime(entry.player, entry.buildrawname, parseFloat(entry.time))}>Aggiorna</button>
          <button className="btn btn-danger ms-1" onClick={() => deleteTime(entry.player, entry.buildrawname)}>Elimina</button>
        </td>
      </tr>
    );
  }
}

const page: Page = {
  title: "SpeedBuilders",
  path: "/speedbuilders",
  description: "Gestisci i best-times delle speedbuilders.",
  icon: "bi bi-lightning-fill",
  showInSidebar: true,
  showInHome: true,
  level: PermissionLevel.MOD,
  component: Render,
};

export default page;