import React from "react";

export enum AuthStatus {
    NOT_AUTHENTICATED,
    PENDING,
    AUTHENTICATED,
}

export enum PermissionLevel {
    SUPPORTER,
    BUILDER,
    HELPER,
    MOD,
    DEV,
    ADMIN,
    FOUNDER
}

export interface AuthInfo {
    status: AuthStatus;
    level?: PermissionLevel;
    username?: string;
    minecraftUsername?: string;
    avatar?: string;
    authId?: string;
    id?: number;
}

interface AuthContextProps {
    info: AuthInfo;
    updateInfo: (info: AuthInfo) => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
    info: {
        status: AuthStatus.PENDING,
    },
    updateInfo: () => {},
});

export function levelToString(level: PermissionLevel) {
    switch (level) {
        case PermissionLevel.SUPPORTER:
            return "Supporter";
        case PermissionLevel.BUILDER:
            return "Builder";
        case PermissionLevel.HELPER:
            return "Helper";
        case PermissionLevel.MOD:
            return "Mod";
        case PermissionLevel.DEV:
            return "Dev";
        case PermissionLevel.ADMIN:
            return "Admin";
        case PermissionLevel.FOUNDER:
            return "Founder";
        default:
            return "Undefined";
    }
}
