import {useContext, useEffect, useState, FormEvent} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {AuthContext, PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"
import useAPI, { filterSuccess } from "../utils/API";
import CondivisioneRete from "./CondivisioneRete";
import NetShareForm, { useNetShareFormData } from "../components/NetShareForm";
import { parsePossibleInt } from "../utils/Utils";
import { ToastContext } from "../utils/ToastContext";

function Render(): JSX.Element {
    const { group_id, user_id } = useParams();
    const navigate = useNavigate();
    const toastContext = useContext(ToastContext);
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    const fetchApi = useAPI();

    const [pendingSave, setPendingSave] = useState(false);
    const [formData, setFormData] = useNetShareFormData();

    useEffect(() => {
        const body = {
            groupid: group_id,
            userid: user_id,
        }

        fetchApi(`/v1/condivisionerete/condivisione`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }).then(response => response.json()).then(data => {
            setFormData(data);
        }).catch(error => {
            console.log(error);
        });
    }, [group_id, user_id]);

    const updateSharingData = (event: FormEvent) => {
        event.preventDefault();

        const body = {
            groupid: parsePossibleInt(group_id),
            username: formData.username1,
            userid: parsePossibleInt(user_id),
            tipo_condivisione: formData.tipo_condivisione,
            url_condivisione: formData.url_condivisione,
            url_ban: formData.url_unban,
            note: formData.note,
        }

        setPendingSave(true);
        fetchApi(`/v1/condivisionerete/aggiorna`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }).then(filterSuccess).then(() => {
            toastContext.addToast("Condivisione Rete (Modifica)", <>Modifica per <b>{formData.username1}</b> effettuata con successo!</>);
        }).catch((error) => {
            toastContext.addToast("Condivisione Rete (Modifica)", <><b className="text-danger">Errore!</b> Qualcosa è andato storto, la modifica non è stata eseguita!</>);
        }).finally(() => setPendingSave(false));
    }

    const deleteSharing = () => {
        setPendingSave(true);
        fetchApi(`/v1/condivisionerete/rimuovi`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                groupid: parsePossibleInt(group_id),
                userid: parsePossibleInt(user_id),
            }),
        }).then(filterSuccess).then(() => {
            toastContext.addToast("Condivisione Rete (Modifica)", <>Condivisione di <b>{formData.username1}</b> rimossa con successo!</>);
        }).catch((error) => {
            toastContext.addToast("Condivisione Rete (Modifica)", <><b className="text-danger">Errore!</b> Qualcosa è andato storto!</>);
        }).finally(() => {
            setPendingSave(false);
            navigate("/condivisione-rete");
        });
    }

    return (
        <div>
            <NetShareForm data={formData} setData={setFormData} pending={pendingSave} onSubmit={updateSharingData} submitButtonText="Modifica!" showUser2={false} />

            <button className="mt-3 btn btn-danger w-100 rounded-5" disabled={pendingSave} onClick={deleteSharing}>
                {pendingSave ? <span className="spinner-border spinner-border-sm" /> : "Elimina Condivisione"}
            </button>
        </div>
    );
}

const page: Page = {
    title: "Modifica Condivisione Rete",
    path: "/condivisione-rete/modifica/:group_id/:user_id",
    description: "Modifica una condivisione rete.",
    icon: "bi bi-check-circle-fill",
    showInSidebar: false,
    showInHome: false,
    level: PermissionLevel.ADMIN,
    topPage: CondivisioneRete,
    component: Render,
};

export default page;