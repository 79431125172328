import { useState, HTMLAttributes } from "react";

interface PaginatedTableProps<T> extends HTMLAttributes<HTMLDivElement> {
  data: T[];
  perPage: number;
  headers: string[];
  rowMapper: (entry: T) => JSX.Element;
}

export function PaginatedTable<T>(props: PaginatedTableProps<T>): JSX.Element {
  const {data, perPage, headers, rowMapper, ...divProps} = props;
  const [page, setPage] = useState(0);

  const pageData = data.slice(perPage * page, perPage * (page + 1));

  return (
    <div {...divProps}>
      <PaginationControls elements={data.length} perPage={perPage} page={page} setPage={setPage} />
      <div className="table-responsive">
        <table className="table table-striped table-hover border">
          <thead>
            <tr>
              { headers.map((header) => <th key={header}>{header}</th>) }
            </tr>
          </thead>
          <tbody>
            { pageData.map((entry) => rowMapper(entry)) }
          </tbody>
        </table>
      </div>
      <PaginationControls elements={data.length} perPage={perPage} page={page} setPage={setPage} />
    </div>
  );
}

function PaginationControls(props: {elements: number, perPage: number, page: number, setPage: (page: number) => void}): JSX.Element {
  const {elements, perPage, page, setPage} = props;

  const lastPage = Math.ceil(elements / perPage);
  const hasPrevPage = page > 0;
  const hasNextPage = page < lastPage - 1;

  return (
    <nav>
      <ul className="pagination fear-pagination">
        <li className="page-item">
          <p className={"page-link " + (hasPrevPage ? "" : "disabled")} onClick={() => setPage(0)} >
            <span aria-hidden="true">&laquo;&laquo;</span>
          </p>
        </li>
        <li className="page-item">
          <p className={"page-link " + (hasPrevPage ? "" : "disabled")} onClick={() => hasPrevPage && setPage(page - 1)} >
            <span aria-hidden="true">&laquo;</span>
          </p>
        </li>
        <li className="page-item w-100 text-center">
          <span className="page-link disabled">{"Pagina: " + (page + 1) + "/" + lastPage}</span>
        </li>
        <li className="page-item">
          <p className={"page-link " + (hasNextPage ? "" : "disabled")} onClick={() => hasNextPage && setPage(page + 1)}>
            <span aria-hidden="true">&raquo;</span>
          </p>
        </li>
        <li className="page-item">
          <p className={"page-link " + (hasNextPage ? "" : "disabled")} onClick={() => setPage(lastPage - 1)} >
            <span aria-hidden="true">&raquo;&raquo;</span>
          </p>
        </li>
      </ul>
    </nav>
  );
}

export default PaginatedTable;
