import { useContext } from "react";
import useAPI from "../utils/API";
import {AuthContext, levelToString, PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"

function Render(): JSX.Element {
    const fetchApi = useAPI();
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    
    const logOut = () => {
        fetchApi("/v1/logout").catch(console.error);
    }

    return (
        <div>
            <h4><i className="bi bi-shield-fill"></i> AMMINISTRAZIONE FEARGAMES</h4>
            <div className="grid-container p-4">
                <img src={authContext.info.avatar} className="rounded-pill" width="200px"></img>
                <div className="p-2">
                    <p><b>Nome:</b> {authContext.info.username}</p>
                    <p><b>Ruolo:</b> {levelToString(authContext.info.level!)}</p>
                    <p><b>Forum: </b><a href={"https://feargames.it/forum/members/" + authContext.info.username + "." + authContext.info.id} target="_blank">{"https://feargames.it/forum/members/" + authContext.info.username + "." + authContext.info.id}</a></p>
                </div>
            </div>

            <hr className="my-4" />

            <h4><i className="bi bi-boxes"></i> MINECRAFT</h4>
            <div className="grid-container p-4">
                <img src={`https://cravatar.eu/helmavatar/${authContext.info.minecraftUsername}/200.png`} className="rounded-pill" width="200px"></img>
                <div className="p-2">
                    <p><b>Nome:</b> {authContext.info.minecraftUsername}</p>
                    <p><b>NameMC:</b> <a href={`https://it.namemc.com/profile/${authContext.info.minecraftUsername}`} target="_blank">{`https://it.namemc.com/profile/${authContext.info.minecraftUsername}`}</a></p>
                </div>
            </div>

            <hr className="my-4" />

            <button className="btn btn-danger rounded-pill w-100" onClick={logOut}>Esci</button>
        </div>
    );
}

const page: Page = {
    title: "Profilo",
    description: "Vedi le informazioni del tuo profilo!",
    path: "/profilo",
    icon: "bi bi-newspaper",
    showInSidebar: false,
    showInHome: false,
    level: PermissionLevel.SUPPORTER,
    component: Render,
};

export default page;