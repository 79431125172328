import {ChangeEvent, FormEvent, useState} from "react";

interface NetShareFormType {
    username1: string;
    username2: string;
    tipo_condivisione: string;
    url_condivisione: string;
    url_unban: string | undefined;
    note: string | undefined;
}

export function useNetShareFormData() {
    return useState<NetShareFormType>({
        username1: "",
        username2: "",
        tipo_condivisione: "0",
        url_condivisione: "",
        url_unban: "",
        note: "",
    });
}

interface Props {
    data: NetShareFormType;
    setData: (value: NetShareFormType) => void;
    onSubmit: (event: FormEvent) => void;
    submitButtonText: string;
    pending: boolean;
    showUser2: boolean;
}

function NetShareForm(props: Props): JSX.Element {
    const handlerFor = (field: keyof NetShareFormType) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const newState = {...props.data};
        newState[field] = event.target.value;
        props.setData(newState);
    };

    return (
        <form onSubmit={props.onSubmit}>
            <div className="input-group">
                <span className="input-group-text">Username 1*</span>
                <input className="form-control" type="text" onChange={handlerFor("username1")} value={props.data.username1} required />
            </div>

            {
                props.showUser2 &&
                <div className="input-group mt-2">
                    <span className="input-group-text">Username 2*</span>
                    <input className="form-control" type="text" onChange={handlerFor("username2")} value={props.data.username2} required />
                </div>
            }

            <div className="input-group mt-2">
                <span className="input-group-text">Tipo*</span>
                <select className="form-select" onChange={handlerFor("tipo_condivisione")} value={props.data.tipo_condivisione} required>
                    <option value="" selected> </option>
                    <option value="parenti">Fratelli / Cugini / Ecc...</option>
                    <option value="amici">Amici</option>
                    <option value="altro">Altro</option>
                </select>
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">URL Condivisione*</span>
                <input className="form-control" type="text" onChange={handlerFor("url_condivisione")} value={props.data.url_condivisione} required />
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">URL Unban</span>
                <input className="form-control" type="text" onChange={handlerFor("url_unban")} value={props.data.url_unban} />
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">Note</span>
                <input className="form-control" type="text" onChange={handlerFor("note")} value={props.data.note} />
            </div>

            <button type="submit" className="mt-3 btn btn-primary w-100 rounded-5" disabled={props.pending}>
                { props.pending ? <span className="spinner-border spinner-border-sm" /> : props.submitButtonText }
            </button>
        </form>
    );
}

export default NetShareForm;