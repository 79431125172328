import {ChangeEvent, FormEvent, useContext, useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";

import useAPI from "../utils/API";

import Image from "react-bootstrap/Image"
import Card from "react-bootstrap/Card";
import {AuthContext, AuthStatus} from "../utils/Auth";

function LoginScreen(): JSX.Element {
    const [pending, setPending] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>();
    const recaptcha = useRef<ReCAPTCHA>(null);

    const authContext = useContext(AuthContext);
    const fetchApi = useAPI();

    const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value.trim());
    };

    const updatePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const updateRecaptcha = (response: string | null) => {
        setRecaptchaResponse(response);
    }

    const doLogin = (event: FormEvent) => {
        event.preventDefault();

        if (!email || !password || !recaptchaResponse) {
            return;
        }

        const body = {
            email,
            password,
            recaptchaResponse,
        }

        setPending(true);
        fetchApi("/v1/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }).then(response => {
            if (response.status === 404) {
                //setError("Credenziali non valide, riprova");
                return;
            } else if (response.status === 200) {
                return response.json().then(body => {
                    const authId = body["x-auth-uniqueid"] as string;
                    authContext.updateInfo({
                        status: AuthStatus.PENDING,
                        authId,
                    });
                });
            } else {
                throw response;
            }
        }).catch(error => {
            console.error(error);
            //setError("Si è verificato un errore inatteso");
        }).finally(() => {
            setPending(false);
            setPassword("");
            recaptcha.current?.reset();
        });
    };

    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <Card className="shadow rounded-4">
                <Card.Header className="text-center py-3">
                    <Image src={process.env.PUBLIC_URL + "/favicon.png"} alt="logo" style={{minHeight: "64px"}} />
                </Card.Header>

                <Card.Body>
                    <form onSubmit={doLogin}>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                            <input type="email" className="form-control" value={email} placeholder="Email" onChange={updateEmail} />
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text"><i className="bi bi-key-fill"></i></span>
                            <input type="password" className="form-control" value={password} placeholder="Password" onChange={updatePassword} />
                        </div>

                        <ReCAPTCHA
                            ref={recaptcha}
                            id="recaptcha-container"
                            sitekey="6LfETa4ZAAAAANNVfm6Z0BDDNO3CEfScq8XwuqmK"
                            onChange={updateRecaptcha}
                            style={{minHeight: "78px", minWidth: "304px"}}
                        />

                        <button type="submit" className="btn btn-primary mt-3 w-100 rounded-5" disabled={pending}>
                            {pending ? <span className="spinner-border spinner-border-sm" role="status" /> : "Accedi"}
                        </button>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default LoginScreen;
