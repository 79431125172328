import { useEffect, useState } from "react";
import useAPI, { filterSuccess } from "../utils/API";
import {PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"

interface TeamSpeakInfo {
  name: string,
  status: string,
  maxClient: number,
  currentClient: number,
  securityLevel: number,
  defaultChannelIdleKickTime: number,
}

function Render(): JSX.Element {
  const fetchApi = useAPI();

  const [teamspeakInfo, setTeamspeakInfo] = useState<TeamSpeakInfo | undefined>(undefined);

  useEffect(() => {
    fetchApi('/v1/teamspeak/info', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(filterSuccess)
    .then(response => response.json())
    .then(data => setTeamspeakInfo(data));
  }, []);

  return (
    <div>
      
    </div>
  );
}

const page: Page = {
  title: "TeamSpeak Controller",
  description: "Amministra il server TeamSpeak da questo pannello.",
  path: "/teamspeak-controller",
  icon: "bi bi-telephone-fill",
  showInSidebar: false,
  showInHome: false,
  level: PermissionLevel.MOD,
  component: Render,
};

export default page;
