import {ChangeEvent, FormEvent, SyntheticEvent, useContext, useState} from "react";

import PaginatedTable from "../components/PaginatedTable";

import {AuthContext, PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"

import Alert from "react-bootstrap/Alert";
import useAPI, { filterSuccess } from "../utils/API";
import { formatTimestamp } from "../utils/Utils";

enum LogLevel {
    CHAT,
    PRIVATE_MESSAGES,
    COMMANDS,
}

export interface LogEntry {
    timestamp: string;
    server: string;
    username: string;
    message: string;
    type: LogLevel;
}

function Render(): JSX.Element {
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    const sliderPermissionCheck = (permissionLevel >= PermissionLevel.MOD);

    const currentDate = new Date(Date.now() - (new Date().getTimezoneOffset() * 60000));
    const startDate = new Date(currentDate);
    startDate.setHours(currentDate.getHours() - 1);

    const [from, setFrom] = useState(startDate.toISOString().substring(0, 16));
    const [to, setTo] = useState(currentDate.toISOString().substring(0, 16));
    const [server, setServer] = useState("");
    const [usernames, setUsernames] = useState("");
    const [level, setLevel] = useState<LogLevel>(LogLevel.CHAT);
    const [logsPerPage, setLogsPerPage] = useState(30);

    const [data, setData] = useState<LogEntry[] | undefined>();
    const [pending, setPending] = useState(false);
    const fetchApi = useAPI();

    const updateFrom = (event: ChangeEvent<HTMLInputElement>) => {
        setFrom(event.target.value);
    }

    const updateTo = (event: ChangeEvent<HTMLInputElement>) => {
        setTo(event.target.value);
    }

    const updateServer = (event: ChangeEvent<HTMLInputElement>) => {
        setServer(event.target.value);
    }

    const updateUsernames = (event: ChangeEvent<HTMLInputElement>) => {
        setUsernames(event.target.value);
    }

    const updateLevel = (event: ChangeEvent<HTMLInputElement>) => {
        const level = parseInt(event.target.value) as LogLevel;
        //if (level > 2) return;
        setLevel(parseInt(event.target.value));
    }

    const updateLogsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
      setLogsPerPage(parseInt(event.target.value));
    }

    const queryChatlog = (event: FormEvent) => {
        event.preventDefault();

        if (!from || !to) {
            return;
        }

        const users = usernames.split(",").map(name => name.trim()).filter(name => name !== "");
        const server = [...document.querySelectorAll("input[type=checkbox]:checked")].flatMap(i => i.getAttribute("value")).join(",");

        console.log(server);

        const body = {
            from,
            to,
            server,
            users,
            level
        }

        setPending(true);
        fetchApi("/v1/chatlogs", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
        .then(filterSuccess)
        .then(response => response.json())
        .then(data => {
            setData(data);
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            setPending(false);
        });
    }

    const servers: {[key: string]: string[]} = {
      "Hub": ["Hub1", "Hub2", "Hub3", "Hub4", "Hub5", "Hub6"],
      "Fazioni": ["Fazioni"],
      "Vanilla": ["Vanilla"],
      "Identity": ["Identity"],
      "Pixelmon": ["Pixelmon"],
      "SkyPrison": ["SkyPrison"],
      "SkyBlock": ["SkyBlock"],
      "Creative": ["Creative"],
      "SkyPvP": ["SkyPvP"],
      "Parkour": ["Parkour"],
      "Murder": ["Murder"],
      "SkyWars": ["SkyWars"],
      "BedWars": ["BWL-1", "BWL-2", "BWL-3", "BWL-4", "BWL-5", "BWG-1", "BWG-2", "BWG-3", "BWG-4", "BWG-5", "BWG-6", "BWG-7", "BWG-8"],
      "CuboLuckyBlock": ["CuboLuckyBlock-1"],
      "BuildBattle": ["BBL", "BB1", "BB2", "BB3", "BB4", "BB5", "BB6", "BB7"],
      "SpeedBuilder": ["SBL", "SB1", "SB2", "SB3", "SB4", "SB5", "SB6", "SB7", "SB8", "SB9", "SB10", "SB11", "SB12", "SB13", "SB14"],
    };

    return (
        <div>
            <Alert key={"waring"} variant={"warning"}>
                Per fare una ricerca su più users si prega di separare gli username da virgola. Esempio: Username1,Username2,Username3<br/>
                Se si vogliono vedere i messaggi di tutto il server non selezionare niente nel campo "Server".
            </Alert>

            <form onSubmit={queryChatlog}>
                <div className="input-group mb-2">
                    <span className="input-group-text">Da</span>
                    <input type="datetime-local" name="from" id="from" className="form-control" value={from} onChange={updateFrom} />
                    <span className="input-group-text">A</span>
                    <input type="datetime-local" name="to" id="to" className="form-control" value={to} onChange={updateTo} />
                </div>

                <div className="mb-2 card rounded-2" style={{borderColor: "#dee2e6"}}>
                  <div className="card-header" style={{backgroundColor: "#f8f9fa"}}>Server</div>
                  <div className="card-body" style={{backgroundColor: "#f3f3f3"}}>
                    {
                      Object.entries(servers).map((server) => 
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" value={server[1].join(",").toLowerCase()} id={"serverCheck-" + server[0]} />
                          <label className="form-check-label" htmlFor={"serverCheck-" + server[0]}>{server[0]}</label>
                        </div>
                      )
                    }
                  </div>
                </div>

                <div className="input-group mb-2">
                    <span className="input-group-text">Usernames</span>
                    <input type="text" name="usernames" id="usernames" className="form-control" value={usernames} onChange={updateUsernames} />
                </div>

                <div className="input-group mb-2">
                    <span className="input-group-text">Logs per pagina</span>
                    <input type="text" name="logsPerPage" id="logsPerPage" className="form-control" value={logsPerPage} onChange={updateLogsPerPage} />
                </div>

                <div className="input-group mb-2 w-100">
                    <input type="range" className="form-range" id="level" min="0" max={sliderPermissionCheck ? "2" : "1"} value={level} onChange={updateLevel} />
                    <div className="d-flex w-100">
                        <label className="form-label text-start" style={{flex: "1 1 0"}}>Chat</label>
                        <label className={"form-label " + (!sliderPermissionCheck ? "text-end" : "text-center")} style={{flex: "1 1 0"}}>Messaggi privati</label>
                        { sliderPermissionCheck && <label className="form-label text-end" style={{flex: "1 1 0"}}>Comandi</label> }
                    </div>
                </div>

                <button type="submit" className="btn btn-primary w-100 rounded-5" disabled={pending}>
                    {pending ? <span className="spinner-border spinner-border-sm" /> : "Cerca Chat"}
                </button>
            </form>

            {data !== undefined && <PaginatedTable className="mt-5" data={data} perPage={logsPerPage} headers={["Orario", "Server", "Username", "Messaggio"]} rowMapper={mapRow} />}
        </div>
    );
}

function mapRow(entry: LogEntry): JSX.Element {
    return (
        <tr className={mapColor(entry)} key={entry.timestamp + "-" + entry.message + "-" + entry.username}>
            <td style={{whiteSpace: "nowrap"}}>{formatTimestamp(entry.timestamp)}</td>
            <td>{entry.server}</td>
            <td>{entry.username}</td>
            <td className={"text-break"}>{entry.message}</td>
        </tr>
    );
}

function mapColor(entry: LogEntry) {
    if (entry.type === LogLevel.PRIVATE_MESSAGES) return "private-message";
    if (entry.type === LogLevel.COMMANDS) return "command";
}

const page: Page = {
    title: "Chatlog",
    description: "Visualizza e ricerca nello storico delle chat di tutto il server di FearGames.",
    path: "/chatlog",
    icon: "bi bi-chat-left-text-fill",
    showInSidebar: true,
    showInHome: true,
    level: PermissionLevel.SUPPORTER,
    component: Render,
};

export default page;
