import {useContext} from "react";
import {AuthContext, AuthStatus} from "./Auth";

function useAPI(): (path: string, init?: RequestInit) => Promise<Response> {
    const authContext = useContext(AuthContext);
    const authId = authContext.info.authId;

    return (path: string, init?: RequestInit): Promise<Response> => {
        if (!init) init = {};
        init.credentials = "include";

        if (authId) {
            if (!init.headers) init.headers = {};
            if (init.headers instanceof Headers) {
                init.headers.set("x-auth-uniqueid", authId);
            } else if (Array.isArray(init.headers)) {
                init.headers.push(["x-auth-uniqueid", authId]);
            } else {
                init.headers["x-auth-uniqueid"] = authId;
            }
        }

        return fetch(getApiBaseUrl() + path, init).then(response => {
            if (response.status === 401) {
                authContext.updateInfo({ status: AuthStatus.NOT_AUTHENTICATED });
                throw response;
            }
            return response;
        });
    };
}

function getApiBaseUrl(): string {
    return "https://amministrazione.feargames.it/api";
}

export function filterSuccess(response: Response): Response {
    if (response.ok) return response;
    throw response;
}

export default useAPI;
