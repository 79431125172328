import {AuthContext, PermissionLevel} from "../utils/Auth"
import {formatTimestamp} from "../utils/Utils";
import {Page} from "../utils/Pages"

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import useAPI, { filterSuccess } from "../utils/API";
import PaginatedTable from "../components/PaginatedTable";
import Alert from "react-bootstrap/esm/Alert";
import { Link } from "react-router-dom";
import NetShareForm, { useNetShareFormData } from "../components/NetShareForm";
import { ToastContext } from "../utils/ToastContext";

export interface CondivisioneEntry {
    name: string,
    tipo_condivisione: string,
    fatto_da: string,
    data: string,
    note?: string,
    group_id?: number,
    user_id?: number,
    url_condivisione?: string,
    url_unban?: string,
}

function Render(): JSX.Element {
    const toastContext = useContext(ToastContext);
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    const fetchApi = useAPI();

    const [pending, setPending] = useState(false);
    const [singeShareData, setSingeShareData] = useState<CondivisioneEntry[] | undefined>();
    const [generalSharingData, setGeneralSharingData] = useState<CondivisioneEntry[] | undefined>();

    const [username, setUsername] = useState("");
    const [searchText, setSearchText] = useState("");

    const [netShareData, setNetShareData] = useNetShareFormData();
    const [netSharePending, setNetSharePending] = useState(false);

    useEffect(() => {
        fetchApi("/v1/condivisionerete/vistagenerale").then(response => response.json()).then(data => {
            setGeneralSharingData(data);
            //setGeneralSharingData(generalSharingData!.sort((a, b) => a.group_id! - b.group_id!));
        }).catch(error => {
            console.log(error);
        });
    }, []);

    const updateUsername = (event: ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    }

    const queryUsername = (event: FormEvent) => {
        event.preventDefault();

        if (!username) return;

        const body = {
            username,
        }

        setPending(true);
        fetchApi("/v1/condivisionerete/search", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }).then(response => response.json()).then(data => {
            setSingeShareData(data);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setPending(false);
        });
    }

    const addNetShare = (event: FormEvent) => {
        event.preventDefault();

        setNetSharePending(true);
        fetchApi("/v1/condivisionerete/aggiungi", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(netShareData),
        }).then(filterSuccess).then(response => response.json()).then(data => {
            toastContext.addToast("Condivisione Rete (Aggiungi)", <>Nuova condivisione aggiunta con successo per utenti <b>{netShareData.username1}</b> e <b>{netShareData.username2}</b>!</>);
        }).catch(error => {
            toastContext.addToast("Condivisione Rete (Aggiungi)", <><b className="text-danger">Errore!</b> Qualcosa è andato storto!</>);
        }).finally(() => {
            setNetSharePending(false);
        });

    }

    return (
        <div>
            <Tabs defaultActiveKey={"cercaCondivisione"}>
                <Tab eventKey={"cercaCondivisione"} title={"Cerca condivisione"}>
                    <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                        <form onSubmit={queryUsername}>
                            <div className="input-group">
                                <span className="input-group-text">Username</span>
                                <input type="text" className="form-control" name="username" id="username" value={username} onChange={updateUsername}/>
                            </div>

                            <button type="submit" className="mt-3 btn btn-primary w-100 rounded-5" disabled={pending}>
                                {pending ? <span className="spinner-border spinner-border-sm" /> : "Cerca Condivisione"}
                            </button>

                            {singeShareData !== undefined && <PaginatedTable className="mt-5" data={singeShareData} perPage={100} headers={["Nome", "Tipo", "Fatto Da", "Data", "Nota"]} rowMapper={mapRow} />}
                        </form>
                    </div>
                </Tab>
                {
                    permissionLevel >= PermissionLevel.MOD &&
                    <Tab eventKey={"aggiungiCondivisione"} title={"Aggiungi condivisione"}>
                        <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                            <Alert variant="warning">
                                Nel campo note mettete le informazioni che possono risolvere eventuali ambiguità, in particolare se usate il campo "Altro" nel tipo di condivisione.
                            </Alert>

                            <NetShareForm data={netShareData} setData={setNetShareData} pending={netSharePending} onSubmit={addNetShare} submitButtonText={"Aggiungi!"} showUser2={true} />
                        </div>
                    </Tab>
                }
                {
                    permissionLevel >= PermissionLevel.ADMIN &&
                    <Tab eventKey={"vistaGenerale"} title={"Vista generale"}>
                        <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                            <input type="text" className="form-control mb-3 w-100" value={searchText} onChange={(event) => setSearchText(event.target.value)} placeholder="Cerca username..." />
                            { generalSharingData !== undefined && <PaginatedTable data={generalSharingData.filter((v) => v.name.includes(searchText))} perPage={100} headers={["Nome", "Tipo", "Fatto Da", "Data", "Nota", "Link Condivisione", "Link Unban", "Modifica"]} rowMapper={MapRowGeneral} />}
                        </div>
                    </Tab>
                }
            </Tabs>
        </div>
    );
}

function mapRow(entry: CondivisioneEntry): JSX.Element {
    return (
        <tr>
            <td>{entry.name}</td>
            <td>{entry.tipo_condivisione}</td>
            <td>{entry.fatto_da}</td>
            <td>{formatTimestamp(entry.data)}</td>
            <td>{entry.note}</td>
        </tr>
    );
}

function MapRowGeneral(entry: CondivisioneEntry): JSX.Element {
    const color = entry.group_id! % 2 == 0 ? "color1" : "color2";

    return (
        <tr className={color}>
            <td>{entry.name}</td>
            <td>{entry.tipo_condivisione}</td>
            <td>{entry.fatto_da}</td>
            <td>{formatTimestamp(entry.data)}</td>
            <td>{entry.note}</td>
            <td><a href={entry.url_condivisione} target={"_blank"}>{entry.url_condivisione}</a></td>
            <td><a href={entry.url_unban} target={"_blank"}>{entry.url_unban}</a></td>
            <td><Link className="btn btn-primary rounded-pill" to={`/condivisione-rete/modifica/${entry.group_id}/${entry.user_id}`}>Modifica</Link></td>
        </tr>
    );
}

const page: Page = {
    title: "Condivisione Rete",
    description: "Verifica, aggiungi e/o rimuovi condivisioni rete.",
    path: "/condivisione-rete",
    icon: "bi bi-people-fill",
    showInSidebar: true,
    showInHome: true,
    level: PermissionLevel.SUPPORTER,
    component: Render,
};

export default page;