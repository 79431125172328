import { PermissionLevel } from "../utils/Auth"
import { Page } from "../utils/Pages"

import useAPI, { filterSuccess } from "../utils/API";
import { useState } from "react";
import PaginatedTable from "../components/PaginatedTable";
import { formatTimestamp } from "../utils/Utils";

interface LogEntry {
  dateTime: string;
  playerName: string;
  description: string;
  violation: number;
  trustFactor: string;
  preventionType: string;
  brandClient: string;
  clientVersion: string;
}

function Render(): JSX.Element {
  const fetchApi = useAPI();

  const currentDate = new Date(Date.now() - (new Date().getTimezoneOffset() * 60000));
  const startDate = new Date(currentDate);
  startDate.setHours(currentDate.getHours() - 1);

  const [from, setFrom] = useState(startDate.toISOString().substring(0, 16));
  const [to, setTo] = useState(currentDate.toISOString().substring(0, 16));
  const [prevention, setPrevention] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<string | undefined>(undefined);
  const [trust, setTrust] = useState<string | undefined>(undefined);
  const [logsPerPage, setLogsPerPage] = useState(30);

  const [data, setData] = useState<LogEntry[] | undefined>();
  const [pending, setPending] = useState<boolean>(false);

  const queryLogs = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!from || !to) return;

    const body = {
      from,
      to,
      prevention,
      user,
      trust: trust === "" ? undefined : trust,
    }

    setPending(true);
    fetchApi('/v1/anticheatlogs/', {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    .then(filterSuccess)
    .then(response => response.json())
    .then(data => setData(data))
    .catch(error => console.log(error))
    .finally(() => setPending(false));
  }

  return (
    <div>
      <form onSubmit={queryLogs}>
        <div className="input-group mb-2">
          <span className="input-group-text">Da</span>
          <input type="datetime-local" name="from" id="from" className="form-control" value={from} onChange={e => setFrom(e.target.value)} />
          <span className="input-group-text">A</span>
          <input type="datetime-local" name="to" id="to" className="form-control" value={to} onChange={e => setTo(e.target.value)} />
        </div>

        <div className="input-group mb-2">
          <span className="input-group-text">Prevention</span>
          <input type="text" name="prevention" id="prevention" className="form-control" value={prevention} onChange={e => setPrevention(e.target.value)} />
        </div>

        <div className="input-group mb-2">
          <span className="input-group-text">User</span>
          <input type="text" name="user" id="user" className="form-control" value={user} onChange={e => setUser(e.target.value)} />
        </div>

        <div className="input-group mb-2">
          <select name="trust" id="trust" className="form-select" onChange={e => setTrust(e.target.value)}>
            <option value="" selected>Trust Factor: Tutti</option>
            <option value="DARK_RED">Trust Factor: Dark Red</option>
            <option value="RED">Trust Factor: Red</option>
            <option value="ORANGE">Trust Factor: Orange</option>
            <option value="YELLOW">Trust Factor: Yellow</option>
            <option value="GREEN">Trust Factor: Green</option>
          </select>
        </div>

        <div className="input-group mb-2">
            <span className="input-group-text">Logs per pagina</span>
            <input type="text" name="logsPerPage" id="logsPerPage" className="form-control" value={logsPerPage} onChange={e => setLogsPerPage(Number.parseInt(e.target.value))} />
        </div>

        <button type="submit" className="btn btn-primary w-100 rounded-5" disabled={pending}>
          {pending ? <span className="spinner-border spinner-border-sm" /> : "Cerca Logs"}
        </button>

        { data &&
          <PaginatedTable data={data} perPage={logsPerPage} headers={['Data', 'Player Name', 'Descrizione', 'Violazione', 'Trust', 'Prevenzione', 'Client', 'Versione']} rowMapper={rowMapper} className="mt-5" />
        }
      </form>
    </div>
  );
}

function rowMapper(entry: LogEntry): JSX.Element {
  return (
    <tr>
      <td>{formatTimestamp(entry.dateTime)}</td>
      <td>{entry.playerName}</td>
      <td>{entry.description}</td>
      <td>{entry.violation}</td>
      <td>{entry.trustFactor}</td>
      <td>{entry.preventionType}</td>
      <td>{entry.brandClient}</td>
      <td>{entry.clientVersion}</td>
    </tr>
  );
}

const page: Page = {
  title: "Anticheats Logs",
  description: "Log di tutti i dati raccolti dall'anticheats.",
  path: "/anticheats-logs",
  icon: "bi bi-crosshair2",
  showInSidebar: true,
  showInHome: true,
  level: PermissionLevel.MOD,
  component: Render,
};

export default page;