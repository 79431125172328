import {PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { FormEvent, useContext, useEffect, useState } from "react";
import useAPI, { filterSuccess } from "../utils/API";
import PaginatedTable from "../components/PaginatedTable";
import { formatTimestamp } from "../utils/Utils";
import { ToastContext, ToastContextProps } from "../utils/ToastContext";

interface ResponseEntry {
    uuid: string;
    banTime: string;
    banner: string;
    reason: string;
}

function Render(): JSX.Element {
    const toastContext = useContext(ToastContext);
    const fetchApi = useAPI();

    const [data, setData] = useState<ResponseEntry[] | undefined>();

    const [searchUUID, setSearchUUID] = useState("");

    const [pending, setPending] = useState(false);
    const [uuid, setUUID] = useState("");
    const [reason, setReason] = useState("");

    const [rowPending, setRowPending] = useState(false);

    const RowMapper = GetRowMapper(toastContext, fetchApi, rowPending, setRowPending);

    useEffect(() => {
        fetchApi(`/v1/bloccouuid`)
        .then(response => response.json()).then(data => {
            setData(data);
        }).catch(error => {
            console.log(error);
        });
    }, []);

    const addUUID = (event: FormEvent) => {
        event.preventDefault();

        setPending(true);
        fetchApi(`/v1/bloccouuid/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uuid,
                motivazione: reason,
            }),
        }).then(filterSuccess).then(response => response.json()).then(data => {
            toastContext.addToast("Blocco UUID (Aggiungi)", <>UUID <b>{uuid}</b> aggiunto con successo!</>)
        }).catch((error: Response) => error.json()).then((errorText) => {
            toastContext.addToast("Blocco UUID (Aggiungi)", <><b className="text-danger">Erore!</b> {errorText["message"]}</>)
        }).finally(() => setPending(false));
    }

    return (
        <div>
            <Tabs defaultActiveKey={"listaBlocchiUUID"}>
                <Tab eventKey={"listaBlocchiUUID"} title={"Lista Blocchi UUID"}>
                    <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                        <input type="text" className="form-control mb-3 w-100" value={searchUUID} onChange={(event) => {setSearchUUID(event.target.value)}} placeholder="Cerca UUID..." />
                        { data !== undefined && <PaginatedTable data={data.filter((entry) => entry.uuid.includes(searchUUID))} perPage={10} headers={["UUID", "Bloccato Da", "Data Di Blocco", "Motivo", "Azioni"]} rowMapper={RowMapper} /> }
                    </div>
                </Tab>
                <Tab eventKey={"aggiungiUUID"} title={"Aggiungi UUID"}>
                    <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                        <form onSubmit={addUUID}>
                            <div className="input-group mt-2">
                                <span className="input-group-text">UUID*</span>
                                <input className="form-control" type="text" onChange={(e) => setUUID(e.target.value)} value={uuid} required />
                            </div>
                            <div className="input-group mt-2">
                                <span className="input-group-text">Motivazione*</span>
                                <input className="form-control" type="text" onChange={(e) => setReason(e.target.value)} value={reason} required />
                            </div>
                            <button type="submit" className="mt-3 btn btn-primary w-100 rounded-5" disabled={pending}>
                                { pending ? <span className="spinner-border spinner-border-sm" /> : "Aggiungi UUID" }
                            </button>
                        </form>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}

function GetRowMapper(toastContext: ToastContextProps, fetchApi: ReturnType<typeof useAPI>, pending: boolean, setPending: (pending: boolean) => void): (entry: ResponseEntry) => JSX.Element {
  return (entry: ResponseEntry) => {
      const unlockUUID = () => {
        setPending(true);
        fetchApi(`/v1/bloccouuid/delete`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uuid: entry.uuid,
            }),
        })
        .then(filterSuccess)
        .then(response => response.json())
        .then(data => {
            toastContext.addToast("Blocco UUID (Sblocca)", <>UUID <b>{entry.uuid}</b> sbloccato con successo!</>)
        })
        .catch((error) => {
            toastContext.addToast("Blocco UUID (Aggiungi)", <><b className="text-danger">Erore!</b> Qualcosa è andato storto!</>)
        })
        .finally(() => {
            setPending(false);
        });
      }

      return(
          <tr key={entry.uuid}>
              <td>{entry.uuid}</td>
              <td>{entry.banner}</td>
              <td>{formatTimestamp(entry.banTime)}</td>
              <td>{entry.reason}</td>
              <td>
                  <button className="btn btn-primary w-100 rounded-5" disabled={pending} onClick={unlockUUID}>
                      {pending ? <span className="spinner-border spinner-border-sm" /> : "Sblocca"}
                  </button>
              </td>
          </tr>
      );
    }
}

const page: Page = {
    title: "Blocco UUID",
    description: "Blocca l'UUID degli account utilizzati per rubare username non premium.",
    path: "/blocco-uuid",
    icon: "bi bi-file-lock-fill",
    showInSidebar: true,
    showInHome: true,
    level: PermissionLevel.DEV,
    component: Render,
};

export default page;