import {useContext, useEffect, useState, FormEvent} from "react";
import {useNavigate, useParams} from "react-router-dom";

import ModAmmesse from "../pages/ModAmmesse";
import ModForm, {useModFormData} from "../components/ModForm";

import {parsePossibleInt} from "../utils/Utils";
import {AuthContext, PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"
import useAPI from "../utils/API";

function Render(): JSX.Element {
    const { modId } = useParams();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    const fetchApi = useAPI();

    const [pendingLoad, setPendingLoad] = useState(true);
    const [pendingSave, setPendingSave] = useState(false);
    const [modData, setModData] = useModFormData();

    useEffect(() => {
        setPendingLoad(true);
        fetchApi(`/v1/modammesse/mod?id=${modId}`)
        .then(response => response.json()).then(data => {
            setModData(data);
        }).catch(error => {
            console.log(error);
        }).finally(() => setPendingLoad(false));
    }, [modId]);

    const updateModData = (event: FormEvent) => {
        event.preventDefault();

        const bodyReq1 = {
            id: modId,
            name: modData.name,
            type: modData.type,
            filename: modData.jar_name,
            link: modData.link,
            description: modData.description,
        };

        const bodyReq2 = {
            id: modId,
            accepted: parsePossibleInt(modData.accepted),
            approved: parsePossibleInt(modData.approved),
        };

        setPendingSave(true);
        Promise.all([
            fetchApi(`/v1/modammesse/edit`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(bodyReq1),
            }).catch(error => {
                console.log(error);
            }),

            fetchApi(`/v1/modammesse/mods`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(bodyReq2),
            }).catch(error => {
                console.log(error);
            }),
        ]).finally(() => setPendingSave(false));
    }

    const deleteMod = () => {
        setPendingSave(true);

        fetchApi(`/v1/modammesse/delete`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({id: modId}),
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setPendingSave(false);
            navigate("/mod-ammesse");
        });
    }

    if (pendingLoad) {
        return (
            <div style={{height: "368px"}}></div>
        );
    }

    return (
        <div>
            <ModForm modData={modData} setModData={setModData} onSubmit={updateModData} submitButtonText="Aggiorna Mod" pending={pendingSave} canApprove={true} />
            <button className="mt-3 btn btn-danger w-100 rounded-5" disabled={pendingSave} onClick={deleteMod}>
                {pendingSave ? <span className="spinner-border spinner-border-sm" /> : "Elimina Mod"}
            </button>
        </div>
    );
}

const page: Page = {
    title: "Modifica Mod",
    path: "/mod-ammesse/modifica/:modId",
    description: "Modifica i campi di una mod all'interno della lista delle mod ammesse.",
    icon: "bi bi-check-circle-fill",
    showInSidebar: false,
    showInHome: false,
    level: PermissionLevel.ADMIN,
    topPage: ModAmmesse,
    component: Render,
};

export default page;