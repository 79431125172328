import {useContext} from "react";
import {AuthContext, PermissionLevel} from "../utils/Auth"
import Pages, {Page} from "../utils/Pages"

import {Link} from "react-router-dom";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ToastContainer from "../components/ToastContainer";

function Render(): JSX.Element {
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    const pagesToDisplay = Pages.filter(page => page.showInHome && page.level <= permissionLevel);

    return (
        <div>
            <h4 className="mb-3"><i className="bi bi-paperclip"></i> PAGINE</h4>
            <div className="grid-container">
                {
                    pagesToDisplay.map(page =>
                        <Card style={{borderRadius: "20px"}}>
                            <Card.Header style={{borderRadius: "20px 20px 0px 0px"}}><i className={page.icon}></i> {page.title}</Card.Header>
                            <Card.Body>{page.description}</Card.Body>
                            <Card.Footer>
                                <Link to={page.path} title={page.title}><Button className="rounded-5" style={{width: "100%"}}>Visualizza</Button></Link>
                            </Card.Footer>
                        </Card>
                    )
                }
            </div>

            <hr className="my-4" />

            <h4 className="mb-3"><i className="bi bi-bell-fill"></i> ATTIVITÀ RECENTI</h4>
            <ToastContainer position={"position-static"} showDeleted={true} className="p-0" />
        </div>
    );
}

const page: Page = {
    title: "Home",
    description: "Homepage del pannello di amministrazione.",
    path: "/",
    icon: "bi bi-house-fill",
    showInSidebar: true,
    showInHome: false,
    level: PermissionLevel.SUPPORTER,
    component: Render,
};

export default page;
