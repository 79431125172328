import { useContext, useEffect } from "react";
import { AuthContext, AuthStatus } from "../utils/Auth";
import useAPI from "../utils/API";

import Spinner from "react-bootstrap/Spinner";

function AuthInitializer(): JSX.Element {
    const authContext = useContext(AuthContext);
    const fetchApi = useAPI();

    useEffect(() => {
        if (!authContext.info.authId) return;
        fetchApi("/v1/info").then(response => response.json()).then(body => {
            authContext.updateInfo({
                status: AuthStatus.AUTHENTICATED,
                level: body["permission_level"],
                username: body["username"],
                minecraftUsername: body["minecraftUsername"],
                avatar: body["avatar"],
                id: body["id"],
                authId: authContext.info.authId,
            });
        }).catch(error => {
            console.error(error);
            authContext.updateInfo({ status: AuthStatus.NOT_AUTHENTICATED });
        });
    }, [authContext, fetchApi]);

    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" variant="primary" role="status" style={{width: "100px", height: "100px"}}>
                <span className="visually-hidden">Caricamento...</span>
            </Spinner>
        </div>
    );
}

export default AuthInitializer;