import {FormEvent, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";

import PaginatedTable from "../components/PaginatedTable";
import ModForm, {useModFormData} from "../components/ModForm";

import {AuthContext, PermissionLevel} from "../utils/Auth";
import {formatTimestamp, parsePossibleInt} from "../utils/Utils";
import {Page} from "../utils/Pages";
import useAPI, {filterSuccess} from "../utils/API";
import { ToastContext } from "../utils/ToastContext";

export interface ModEntry {
    id: number;
    name: string;
    jar_name: string;
    type: string;
    accepted: boolean;
    description?: string;
    link?: string;
    created_by?: string;
    created_at?: string;
    approved?: boolean;
}

function Render(): JSX.Element {
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    const fetchApi = useAPI();

    const toastContext = useContext(ToastContext);

    const [pendingLoad, setPendingLoad] = useState(false);
    const [pendingAdd, setPendingAdd] = useState(false);
    const [addSuccess, setAddSuccess] = useState(false);
    const [modAdmitted, setModAdmitted] = useState<ModEntry[] | undefined>();
    const [modSuggested, setModSuggested] = useState<ModEntry[] | undefined>();
    const [addModData, setAddModData] = useModFormData();

    useEffect(() => {
        setPendingLoad(true);
        Promise.all([
            fetchApi("/v1/modammesse").then(response => response.json()).then(data => {
                setModAdmitted(data);
            }).catch(error => {
                console.log(error);
            }),
            fetchApi("/v1/modammesse/mods").then(response => response.json()).then(data => {
                setModSuggested(data);
            }).catch(error => {
                console.log(error);
            })
        ]).finally(() => setPendingLoad(false));
    }, []);

    const addMod = (event: FormEvent) => {
        event.preventDefault();

        setPendingAdd(true);

        const body = {
            name: addModData.name,
            type: addModData.type,
            filename: addModData.jar_name,
            link: addModData.link,
            accepted: parsePossibleInt(addModData.accepted),
            description: addModData.description,
        }

        fetchApi("/v1/modammesse/suggerisci", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }).then(filterSuccess).then(() => {
            setAddSuccess(true);
            toastContext.addToast("Mod Ammesse", "Mod aggiunta con successo!")
        }).catch(error => {
            console.log(error);
        }).finally(() => setPendingAdd(false));
    }

    return (
        <div>
            <Tabs defaultActiveKey={"listaMod"}>
                <Tab eventKey={"listaMod"} title={"Lista Mod"}>
                    <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                        {modAdmitted !== undefined && <PaginatedTable data={modAdmitted} perPage={500} headers={["Nome", "Nome Jar", "Tipo", "Ammessa"]} rowMapper={mapRowAdmitted} />}
                    </div>
                </Tab>
                { permissionLevel >= PermissionLevel.MOD &&
                    <Tab eventKey={"suggerisciMod"} title={"Suggerisci Mod"}>
                        <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                            { false && <Alert variant="success">Mod aggiunta con successo!</Alert> }
                            <ModForm modData={addModData} setModData={setAddModData} onSubmit={addMod} submitButtonText={"Suggerisci Mod"} pending={pendingAdd} canApprove={false} />
                        </div>
                    </Tab>
                }
                { permissionLevel >= PermissionLevel.ADMIN &&
                    <Tab eventKey={"listaModSuggerite"} title={"Lista Mod Suggerite"}>
                        <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
                            {modSuggested !== undefined && <PaginatedTable data={modSuggested} perPage={500} headers={["Nome", "Nome Jar", "Tipo", "Descrizione", "Link", "Proposto Da", "Data Creazione", "Ammeessa", "Approvata"]} rowMapper={mapRowSuggested} />}
                        </div>
                    </Tab>
                }
            </Tabs>
        </div>
    );
}

function mapRowAdmitted(entry: ModEntry): JSX.Element {
    return (
        <tr>
            <td>{entry.name}</td>
            <td>{entry.jar_name}</td>
            <td>{entry.type}</td>
            <td>{entry.accepted ? <i className="bi bi-check-circle-fill" style={{color: "green"}}></i> : <i className="bi bi-x-circle-fill" style={{color: "red"}}></i>}</td>
        </tr>
    );
}

function mapRowSuggested(entry: ModEntry): JSX.Element {
    return (
        <tr>
            <td>{entry.name}</td>
            <td>{entry.jar_name}</td>
            <td>{entry.type}</td>
            <td>{entry.description}</td>
            <td><a href={entry.link}>{entry.link}</a></td>
            <td>{entry.created_by}</td>
            <td>{formatTimestamp(entry.created_at!)}</td>
            <td>{entry.accepted ? <i className="bi bi-check-circle-fill" style={{color: "green"}}></i> : <i className="bi bi-x-circle-fill" style={{color: "red"}}></i>}</td>
            <td>{entry.approved ? <i className="bi bi-check-circle-fill" style={{color: "green"}}></i> : <i className="bi bi-x-circle-fill" style={{color: "red"}}></i>}</td>
            <td><Link to={`/mod-ammesse/modifica/${entry.id}`} className={"btn btn-primary rounded-pill"}>Modifica</Link></td>
        </tr>
    );
}

const page: Page = {
    title: "Mod Ammesse",
    path: "/mod-ammesse",
    description: "Aggiungi e/o rimuovi le mod ammesse all'interno del Network di FearGames.",
    icon: "bi bi-check-circle-fill",
    showInSidebar: true,
    showInHome: true,
    level: PermissionLevel.SUPPORTER,
    component: Render,
};

export default page;