import {ChangeEvent, FormEvent, useState} from "react";

interface ModFormType {
    name: string;
    type: string;
    jar_name: string;
    link: string;
    description: string;
    accepted: string | number;
    approved: string | number;
}

export function useModFormData() {
    return useState<ModFormType>({
        name: "",
        type: "mod",
        jar_name: "",
        link: "",
        description: "",
        accepted: 0,
        approved: 0,
    });
}

interface ModFormProps {
    modData: ModFormType;
    setModData: (value: ModFormType) => void;
    onSubmit: (event: FormEvent) => void;
    submitButtonText: string;
    pending: boolean;
    canApprove: boolean;
}

function ModForm(props: ModFormProps): JSX.Element {
    const handlerFor = (field: keyof ModFormType) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const newState = {...props.modData};
        newState[field] = event.target.value;
        props.setModData(newState);
    };

    return (
        <form onSubmit={props.onSubmit}>
            <div className="input-group">
                <span className="input-group-text">Nome*</span>
                <input className="form-control" type="text" onChange={handlerFor("name")} value={props.modData.name} required />
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">Tipo*</span>
                <select className="form-select" onChange={handlerFor("type")} value={props.modData.type} required>
                    <option value="mod">Mod</option>
                    <option value="client">Client</option>
                </select>
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">Nome file</span>
                <input className="form-control" type="text" onChange={handlerFor("jar_name")} value={props.modData.jar_name} />
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">Link documentazione</span>
                <input className="form-control" type="text" onChange={handlerFor("link")} value={props.modData.link} />
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">Descrizione*</span>
                <input className="form-control" type="text" onChange={handlerFor("description")} value={props.modData.description} required />
            </div>

            <div className="input-group mt-2">
                <span className="input-group-text">Ammessa*</span>
                <select className="form-select" onChange={handlerFor("accepted")} value={props.modData.accepted} required>
                    <option value="0">No</option>
                    <option value="1">Si</option>
                </select>
            </div>

            { props.canApprove && (
                <div className="input-group mt-2">
                    <span className="input-group-text">Approvata*</span>
                    <select className="form-select" onChange={handlerFor("approved")} value={props.modData.approved} required>
                        <option value="0">No</option>
                        <option value="1">Si</option>
                    </select>
                </div>
            )}

            <button type="submit" className="mt-3 btn btn-primary w-100 rounded-5" disabled={props.pending}>
                {props.pending ? <span className="spinner-border spinner-border-sm" /> : props.submitButtonText}
            </button>
        </form>
    );
}

export default ModForm;