import React from "react";

export interface ToastInfo {
  title: string;
  message: JSX.Element | string;
  timestamp: number;
  visible: boolean;
};

export interface ToastContextProps {
  toasts: ToastInfo[];
  addToast: (title: string, message: JSX.Element | string) => void;
  removeToast: (toast: ToastInfo) => void;
};

export const ToastContext = React.createContext<ToastContextProps>({
  toasts: [],
  addToast: () => {},
  removeToast: () => {},
});