import { useState } from "react";
import {PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"

interface UserInfo {
    username: string;
}

function Render(): JSX.Element {
    const [user1, setUser1] = useState<UserInfo>({username: ""});
    const [user2, setUser2] = useState<UserInfo>({username: ""});

    return (
        <div>
            <div className="grid-container mb-3">
                <div className="card">
                    <div className="card-header">
                        Vecchio Account
                    </div>
                    <div className="card-body">
                        <img src={`https://cravatar.eu/helmavatar/${user1?.username}/300.png`} width="300px" />
                        <div className="input-group mt-2">
                            <label className="input-group-text" htmlFor="user1-username">Username: </label>
                            <input type="text" className="form-control" id="user1-username" value={user1?.username} onChange={(event) => setUser1({username: event.target.value})}></input>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        Nuovo Account
                    </div>
                    <div className="card-body">
                        <img src={`https://cravatar.eu/helmavatar/${user2?.username}/300.png`} width="300px" />
                        <div className="input-group mt-2">
                            <label className="input-group-text" htmlFor="user2-username">Username: </label>
                            <input type="text" className="form-control" id="user2-username" value={user2?.username} onChange={(event) => setUser2({username: event.target.value})}></input>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        Opzioni
                    </div>
                    <div className="card-body">
                        <div className="form-check mt-2">
                            <input type="checkbox" className="form-check-input" id="opzioni-trasferimento-pagato"/>
                            <label className="form-check-label" htmlFor="opzioni-trasferimento-pagato">Pagato</label>
                        </div>

                        <hr />

                        <div className="form-check mt-2">
                            <input type="checkbox" className="form-check-input" id="opzioni-vanilla"/>
                            <label className="form-check-label" htmlFor="opzioni-vanilla">Vanilla</label>
                        </div>
                        <div className="form-check mt-2">
                            <input type="checkbox" className="form-check-input" id="opzioni-fazioni"/>
                            <label className="form-check-label" htmlFor="opzioni-fazioni">Fazioni</label>
                        </div>
                        <div className="form-check mt-2">
                            <input type="checkbox" className="form-check-input" id="opzioni-creative"/>
                            <label className="form-check-label" htmlFor="opzioni-creative">Creative</label>
                        </div>

                        <hr />

                        
                    </div>
                </div>
            </div>
            <button className="btn btn-primary w-100 rounded-pill">Trasferisci!</button>
        </div>
    );
}

const page: Page = {
    title: "Cambio Account",
    description: "Trasferisci le statistiche da un vecchio username ad uno nuovo.",
    path: "/cambio-account",
    icon: "bi bi bi-arrow-clockwise",
    showInSidebar: false,
    showInHome: false,
    level: PermissionLevel.ADMIN,
    component: Render,
};

export default page;