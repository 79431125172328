import { DetailedHTMLProps, HTMLAttributes, HTMLProps, useContext } from "react";
import { ToastContext } from "../utils/ToastContext";
import { formatTimestamp } from "../utils/Utils";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    position: string;
    showDeleted: boolean;
};

function ToastContainer(props: Props): JSX.Element {
    const toastContext = useContext(ToastContext);
    const containerClass = props.showDeleted ? "grid-container" : "toast-container";
    const {position, showDeleted, ...divProps} = props;

    return (
        <div {...divProps} className={`${containerClass} ${props.position} ${divProps.className}`}>
            {
                props.showDeleted && toastContext.toasts.length == 0 && <p className="text-muted">Nessuna attività recente!</p>
            }
            { toastContext.toasts.map((toast) => 
                (toast.visible || props.showDeleted) &&
                <div className="toast show" role="alert">
                    <div className="toast-header">
                        <i className="bi bi-bell-fill">&nbsp;</i>
                        <strong className="me-auto">{toast.title}</strong>
                        <small className="text-muted">{formatTimestamp(new Date(toast.timestamp).toISOString())}</small>
                        { !props.showDeleted && <button type="button" className="btn-close" data-bs-dismiss="toast" onClick={() => toastContext.removeToast(toast)}></button> }
                    </div>
                    <div className="toast-body">{toast.message}</div>
                </div>
            )}
        </div>
    );
}

export default ToastContainer;