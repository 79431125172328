import React, {SyntheticEvent, useContext} from 'react';
import {Link} from "react-router-dom";

import {AuthContext, AuthStatus, levelToString} from "../utils/Auth"
import Pages, {Page} from "../utils/Pages";
import useAPI from '../utils/API';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import defaultAvatar from 'bootstrap-icons/icons/person-fill.svg'

function Sidebar(): JSX.Element {
    const authContext = useContext(AuthContext);
    const permissionLevel = authContext.info.level!;
    const fetchApi = useAPI();

    const logOut = () => {
        fetchApi("/v1/logout").catch(console.error);
    }

    const setDefaultAvatar = (event: SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.src = defaultAvatar;
    }
    const avatar = <img alt="" src={authContext.info.avatar} className="rounded-4" onError={setDefaultAvatar} />;

    return (
        <ul className="navbar">
            <li className="navbar-item">
                <DropdownButton
                    as={ButtonGroup}
                    key="end"
                    id="dropdown-button-profile"
                    drop="end"
                    variant="secondary"
                    title={avatar}
                    >
                    <Dropdown.Item eventKey="1"><Link to="/profilo" className='fear-link'><i className="bi bi-person-fill"></i> <b>Nome: </b>{authContext.info.username}</Link></Dropdown.Item>
                    <Dropdown.Item eventKey="2"><i className="bi bi-shield-fill"></i> <b>Ruolo: </b>{levelToString(authContext.info.level!)}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="4" onClick={logOut}><i className="bi bi-x-circle-fill"></i> <b>Esci</b></Dropdown.Item>
                </DropdownButton>
            </li>
            { Pages.filter(entry => entry.level <= permissionLevel && entry.showInSidebar).map(SidebarEntry) }
        </ul>
    );
}

function SidebarEntry(props: Page): JSX.Element {
    return (
        <li className="navbar-item" key={props.path}>
            <Link className="border" to={props.path} title={props.title}><i className={props.icon}></i></Link>
        </li>
    );
}

export default Sidebar;
