import { PermissionLevel } from "../utils/Auth"
import { Page } from "../utils/Pages"

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import useAPI, { filterSuccess } from "../utils/API";
import { useContext, useEffect, useState } from "react";
import PaginatedTable from "../components/PaginatedTable";
import { formatTimestamp } from "../utils/Utils";
import { ToastContext, ToastContextProps } from "../utils/ToastContext";

interface Response {
  username: string;
  blocked: boolean;
  data?: string;
}

interface ResponsePremium {
  Name: string;
}

function Render(): JSX.Element {
  const toastContext = useContext(ToastContext);
  const fetchApi = useAPI();

  const [spData, setSpData] = useState<Response[] | undefined>();
  const [premiumData, setPremiumData] = useState<ResponsePremium[] | undefined>();

  const [searchTextSp, setSearchTextSp] = useState("");
  const [searchTextPremium, setSearchTextPremium] = useState("");

  const [rowPending, setRowPending] = useState(false);

  const RowMepperSp = GetRowMapperSp(toastContext, fetchApi, rowPending, setRowPending);
  const RowMapperPremium = GetRowMapperPremium(toastContext, fetchApi);

  useEffect(() => {
    fetchApi("/v1/recuperoaccount/sp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(filterSuccess)
    .then(response => response.json())
    .then(data => setSpData(data))
    .catch(error => console.log(error));

    fetchApi("/v1/recuperoaccount/premium", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"search": undefined}),
    })
    .then(filterSuccess)
    .then(response => response.json())
    .then(data => setPremiumData(data))
    .catch(error => console.log(error));
  }, []);

  return (
    <div>
      <Tabs defaultActiveKey={"sp"}>
        <Tab eventKey={"sp"} title={"SP"}>
          <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
            <input type="text" className="form-control mb-3 w-100" value={searchTextSp} onChange={(e) => setSearchTextSp(e.target.value)} placeholder="Cerca username..." />
            { spData &&
              <PaginatedTable data={spData.filter((v) => v.username.includes(searchTextSp))} perPage={10} headers={["Username", "Stato", "Data Unregistrazione", "Azioni"]} rowMapper={RowMepperSp} />
            }
          </div>
        </Tab>
        <Tab eventKey={"premium"} title={"Premium"}>
          <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
            <input type="text" className="form-control mb-3 w-100" value={searchTextPremium} onChange={(e) => setSearchTextPremium(e.target.value)} placeholder="Cerca username..." />
            { premiumData &&
              <PaginatedTable data={premiumData.filter((v) => v.Name.includes(searchTextPremium))} perPage={10} headers={["Username", "Azioni"]} rowMapper={RowMapperPremium} />
            }
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

function GetRowMapperSp(toastContext: ToastContextProps, fetchApi: ReturnType<typeof useAPI>, pending: boolean, setPending: (pending: boolean) => void): (entry: Response) => JSX.Element {
  return (entry: Response) => {
    const deleteEntry = (username: string) => {
      setPending(true);
      fetchApi("/v1/recuperoaccount/sp/elimina", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
        }),
      })
      .then(filterSuccess)
      .then(response => response.json())
      .then()
      .catch(error => console.log(error))
      .finally(() => setPending(false));
    };
  
    const unlockEntry = (username: string) => {
      setPending(true);
      fetchApi("/v1/recuperoaccount/sp/sblocca", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
        }),
      })
      .then(filterSuccess)
      .then(response => response.json())
      .then(data => {
        toastContext.addToast("Recupero Account (SP)", <>{data["message"]}. Account di <b>{username}</b> sbloccato!</>)
      })
      .catch(error => {
        toastContext.addToast("Recupero Account (SP)", error)
      })
      .finally(() => setPending(false));
    };
  
    const isOld = (dateString: string | undefined) => {
      if (!dateString) return false;
  
      const date = new Date(dateString).getTime();
      const now = new Date().getTime();
  
      if (now - date <= 2.592e+9) return false;
  
      return true;
    }
  
    return (
      <tr>
        <td>{entry.blocked ? <a href={`https://it.namemc.com/profile/${entry.username}`} target="_blank">{entry.username}</a> : entry.username}</td>
        <td>
          { entry.blocked ? (isOld(entry.data) ? <p className="unregistered-tag-orange">Unregistrato</p> : <p className="unregistered-tag">Unregistrato</p>) : <p className="ok-tag">Ok</p> }
        </td>
        <td>{formatTimestamp(entry.data ? entry.data : "")}</td>
        <td>
          <button className="btn btn-primary rounded-pill me-1 w-25" disabled={pending} onClick={() => unlockEntry(entry.username)}>
            { pending ? <span className="spinner-border spinner-border-sm" /> : "Sblocca" }
          </button>
          <button className="btn btn-danger rounded-pill ms-1 w-25" disabled={pending} onClick={() => deleteEntry(entry.username)}>
            { pending ? <span className="spinner-border spinner-border-sm" /> : "Elimina" }
          </button>
        </td>
      </tr>
    );
  }
}

function GetRowMapperPremium(toastContext: ToastContextProps, fetchApi: ReturnType<typeof useAPI>): (entry: ResponsePremium) => JSX.Element {
  return (entry: ResponsePremium) => {
    const deleteEntry = (username: string) => {
      fetchApi("/v1/recuperoaccount/premium/elimina", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
        }),
      })
      .then(filterSuccess)
      .then(response => response.json())
      .then(data => {
        toastContext.addToast("Recupero Account (Premium)", <>{data["message"]}. Account di <b>{username}</b> eliminato!</>)
      })
      .catch(error => {
        console.log(error);
      });
    };
  
    return (
      <tr>
        <td>{entry.Name}</td>
        <td> <button className="btn btn-danger rounded-pill ms-1" onClick={() => deleteEntry(entry.Name)}>Elimina</button></td>
      </tr>
    );
  }
}

const page: Page = {
  title: "Recupero Account",
  description: "Verifica il corretto recupero degli account da parte dei giocatori.",
  path: "/recupero-account",
  icon: "bi bi-key-fill",
  showInSidebar: true,
  showInHome: true,
  level: PermissionLevel.DEV,
  component: Render,
};

export default page;