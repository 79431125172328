import {AuthContext, PermissionLevel} from "../utils/Auth"
import {Page} from "../utils/Pages"

import {ChangeEvent, FormEvent, useContext, useState} from "react";
import useAPI from "../utils/API";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PaginatedTable from "../components/PaginatedTable";
import { parsePossibleInt, secondsToDays } from "../utils/Utils";

interface MinecraftEntry {
  username: string;
  playtime: string;
  controlli: string;
  bans: string;
  kicks: string;
  mutes: string;
  messages: string;
  pre_playtime: string;
  pre_controlli: string;
  pre_bans: string;
  pre_kicks: string;
  pre_mutes: string;
  pre_messages: string;
}

function Render(): JSX.Element {
  const authContext = useContext(AuthContext);
  const permissionLevel = authContext.info.level!;
  const fetchApi = useAPI();

  const [pending, setPending] = useState(false);
  const [minecraftData, setMinecraftData] = useState();
  const [teamspeakData, setTeamspeakData] = useState();

  const [dateBegin, setDateBegin] = useState(new Date().toISOString());
  const [dateEnd, setDateEnd] = useState(new Date().toISOString());

  const getMinecraft = (event: FormEvent) => {
    event.preventDefault();

    const body = {
      dateBegin,
      dateEnd,
    }

    setPending(true);
    fetchApi("/v1/staffstats/minecraft", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(response => response.json()).then(data => {
      setMinecraftData(data);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setPending(false);
    });
  }

  const getTeamspeak = (event: FormEvent) => {
    event.preventDefault();

    const body = {
      dateBegin,
      dateEnd,
    }

    setPending(true);
    fetchApi("/v1/staffstats/teamspeak", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(response => response.json()).then(data => {
      setTeamspeakData(data);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setPending(false);
    });
  }

  return (
    <div>
      <Tabs defaultActiveKey={"minecraft"}>
        {
          permissionLevel >= PermissionLevel.ADMIN &&
          <Tab eventKey={"minecraft"} title={"Stats Minecraft"}>
            <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
              <form onSubmit={getMinecraft}>
                <div className="input-group">
                  <span className="input-group-text">Da</span>
                  <input type="date" className="form-control" value={dateBegin} onChange={e => setDateBegin(e.target.value)}/>

                  <span className="input-group-text">A</span>
                  <input type="date" className="form-control" value={dateEnd} onChange={e => setDateEnd(e.target.value)}/>
                </div>

                <button type="submit" className="mt-3 mb-3 btn btn-primary w-100 rounded-5" disabled={pending}>
                  {pending ? <span className="spinner-border spinner-border-sm" /> : "Visualizza"}
                </button>

                { minecraftData !== undefined &&
                  <PaginatedTable data={minecraftData} perPage={100} headers={["Username", "Playtime", "Controlli", "Bans", "Kicks", "Mutes", "Messages"]} rowMapper={MinecraftRowMapper} />
                }
              </form>
            </div>
          </Tab>
        }
        {
          permissionLevel >= PermissionLevel.ADMIN &&
          <Tab eventKey={"teamspeak"} title={"Stats TeamSpeak"}>
            <div className="p-3 border-start border-bottom border-end" style={{borderRadius: "0px 0px 20px 20px"}}>
              <form onSubmit={getTeamspeak}>
                <div className="input-group">
                  <span className="input-group-text">Data</span>
                  <input type="date" className="form-control" value={dateBegin} onChange={e => setDateBegin(e.target.value)}/>
                </div>

                <button type="submit" className="mt-3 btn btn-primary w-100 rounded-5" disabled={pending}>
                  {pending ? <span className="spinner-border spinner-border-sm" /> : "Visualizza"}
                </button>
              </form>
            </div>
          </Tab>
        }
      </Tabs>
    </div>
  );
}

function MinecraftRowMapper(entry: MinecraftEntry): JSX.Element {
  const playtime = parsePossibleInt(entry.playtime);
  const controlli = parsePossibleInt(entry.controlli);
  const bans = parsePossibleInt(entry.bans);
  const kicks = parsePossibleInt(entry.kicks);
  const mutes = parsePossibleInt(entry.mutes);
  const messages = parsePossibleInt(entry.messages);

  const playtime_diff = secondsToDays(playtime - parsePossibleInt(entry.pre_playtime));
  const controlli_diff = controlli - parsePossibleInt(entry.pre_controlli);
  const bans_diff = bans - parsePossibleInt(entry.pre_bans)
  const kicks_diff = kicks - parsePossibleInt(entry.pre_kicks);
  const mutes_diff = mutes - parsePossibleInt(entry.pre_mutes);
  const messages_diff = messages - parsePossibleInt(entry.pre_messages);

  return (
    <tr>
      <td>{entry.username}</td>
      <td><>{secondsToDays(playtime)} <span className="text-muted">({playtime_diff})</span></></td>
      <td><>{controlli} <span className="text-muted">({(controlli_diff>=0?"+":"") + controlli_diff})</span></></td>
      <td><>{bans} <span className="text-muted">({(bans_diff>=0?"+":"") + bans_diff})</span></></td>
      <td><>{kicks} <span className="text-muted">({(kicks_diff>=0?"+":"") + kicks_diff})</span></></td>
      <td><>{mutes} <span className="text-muted">({(mutes_diff>=0?"+":"") + mutes_diff})</span></></td>
      <td><>{messages} <span className="text-muted">({(messages_diff>=0?"+":"") + messages_diff})</span></></td>
    </tr>
  );
}

const page: Page = {
  title: "Staff Stats",
  description: "Visualizza le statistiche degli staffer.",
  path: "/staff-stats",
  icon: "bi bi bi-trophy-fill",
  showInSidebar: true,
  showInHome: true,
  level: PermissionLevel.ADMIN,
  component: Render,
};

export default page;